import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import GuestGuard from 'utils/route-guard/GuestGuard';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import SubscriptionGuard from 'utils/route-guard/subscriptionGuard';

// Landing Page routing
const AppLanding = Loadable(lazy(() => import('views/pages/frontpage/index')));

// Page Not Found routing
const AppPageNotFound = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// dashboard routing
const UserDashboard = Loadable(lazy(() => import('views/pages/userDashbaord/index')));

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/Register')));
const AppResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword')));

// Profile routing
const UserProfile = Loadable(lazy(() => import('views/pages/userDashbaord/profile/UserProfile')));

// Subscription routing
const UserSubscription = Loadable(lazy(() => import('views/pages/userDashbaord/subscription/Subscription')));

// Checkout routing
const PackageCheckout = Loadable(lazy(() => import('views/pages/checkout')));

// Transaction routing
const UserTransaction = Loadable(lazy(() => import('views/pages/userDashbaord/transaction/Transaction')));

// Ticket routing
const UserTicket = Loadable(lazy(() => import('views/pages/userDashbaord/ticket/Ticket')));

// Setting routing
const UserSetting = Loadable(lazy(() => import('views/pages/userDashbaord/setting/Setting')));

// setup-domain
const UserSetUpDomain = Loadable(lazy(() => import('views/pages/userDashbaord/subscription/newSetupDomain')));
// const UserSetUpDomain = Loadable(lazy(() => import('views/pages/userDashbaord/subscription/SetupDomain')));

// Quyotation form for supplier
const QuotationForm = Loadable(lazy(() => import('views/pages/quotationForm/QuotationForm')));

// Purchase Order form for supplier
const PurchaseOrderForm = Loadable(lazy(() => import('views/pages/purchaseOrderForm/purchaseOrderForm')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AppLanding />
        },
        {
            path: '/dashboard',
            element: (
                <AuthGuard>
                    <UserDashboard />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/dashboard',
                    element: <UserProfile />
                },
                {
                    path: '/dashboard/profile',
                    element: <UserProfile />
                },
                {
                    path: '/dashboard/subscription',
                    element: (
                        <SubscriptionGuard>
                            <UserSubscription />
                        </SubscriptionGuard>
                    )
                },
                {
                    path: '/dashboard/setup-domain',
                    element: <UserSetUpDomain />
                },
                {
                    path: '/dashboard/transaction',
                    element: <UserTransaction />
                },
                {
                    path: '/dashboard/ticket',
                    element: <UserTicket />
                },
                {
                    path: '/dashboard/setting',
                    element: <UserSetting />
                }
            ]
        },
        {
            path: '/reset-password',
            element: <AppResetPassword />
        },
        {
            path: '/checkout',
            element: (
                <AuthGuard>
                    <PackageCheckout />
                </AuthGuard>
            )
        },
        {
            path: '/login',
            element: (
                <GuestGuard>
                    <AuthLogin />
                </GuestGuard>
            )
        },
        // {
        //     path: '/quotation-form',
        //     element: (
        //         <GuestGuard>
        //             <QuotationForm />
        //         </GuestGuard>
        //     )
        // },
        // {
        //     path: '/po-form',
        //     element: (
        //         <GuestGuard>
        //             <PurchaseOrderForm />
        //         </GuestGuard>
        //     )
        // },
        {
            path: '/forgot',
            element: (
                <GuestGuard>
                    <AuthForgotPassword />
                </GuestGuard>
            )
        },
        {
            path: '/register',
            element: (
                <GuestGuard>
                    <AuthRegister />
                </GuestGuard>
            )
        },
        {
            path: '*',
            element: <AppPageNotFound />
        }
    ]
};

export default MainRoutes;
